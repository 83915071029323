<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2 v-if="profileCompletionMode">Complete Your Profile</h2>
                        <h2 v-else>REGISTRATION</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="register-section">
            <div class="row">
                <div class="col-sm-6">
                    <div class="regist-left">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <div class="registinner">
                                        <img src="/images/logo-transprint.png" />
                                        <p>
                                            If you have an account already?<a href="#">
                                                So Login Now.</a
                                            >
                                            And start less than a minute.
                                        </p>
                                        <img src="/images/border-or.png" class="border-or" />
                                        <span>OR</span>
                                        <h3>LOGIN VIA SOCIAL ACCOUNT</h3>
                                        <a href="#" class="login-facebook"
                                            ><i class="fa fa-facebook-f"></i> Login with Facebook</a
                                        >
                                        <a
                                            href="#"
                                            class="login-facebook"
                                            style="background-color: red;"
                                            ><i class="fa fa-google-plus"></i> Login with Google</a
                                        >
                                        <a href="#" class="login-facebook"
                                            ><i class="fa fa-linkedin"></i> Login with Linkedin</a
                                        >
                                        <!--<a href="#" class="login-facebook twtter"><i class="fa fa-twitter-square"></i> Login with Twitter</a>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="regist-right">
                        <div class="container">
                            <div v-if="profileCompletionMode">
                                <div class="row">
                                    <div class="col-sm-9">
                                        <div class="regist-righbox">
                                            <VerifyEmail />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-sm-9">
                                    <div class="regist-righbox">
                                        <h3>REGISTER AS A MATERIAL INSPECTOR</h3>
                                        <p>Fill out the required information below.</p>
                                        <form>
                                            <label class="input-icon"
                                                ><i class="fa fa-user"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="name"
                                                placeholder="Full Name"
                                                class="styleinput"
                                                v-model="name"
                                                required
                                            />
                                            <div class="status" id="name-status"></div>
                                            <br />

                                            <label class="input-icon"
                                                ><i class="fa fa-envelope"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="email"
                                                placeholder="Email Address"
                                                class="styleinput"
                                                v-model="email"
                                                required
                                            />
                                            <div class="status" id="email-status"></div>
                                            <!-- <div v-if="ress && ress.email" class="text-danger">
                                                {{ ress.email[0] }}
                                            </div> -->
                                            <br />

                                            <label class="input-icon"
                                                ><i class="fa fa-unlock-alt"></i
                                            ></label>
                                            <input
                                                :type="showPassword ? 'text' : 'password'"
                                                name="password"
                                                placeholder="Password"
                                                class="styleinput"
                                                v-model="password"
                                                required
                                            />
                                            <div
                                                class="small text-primary"
                                                @click="showPassword = !showPassword"
                                                style="cursor: pointer"
                                            >
                                                {{
                                                    showPassword ? "Hide Password" : "Show Password"
                                                }}
                                            </div>
                                            <div class="status" id="password-status"></div>
                                            <br />

                                            <!-- <label class="input-icon"
                                                ><i class="fa fa-unlock-alt"></i
                                            ></label>
                                            <input
                                                type="password"
                                                name="password_confirmation"
                                                placeholder="Re-enter Password"
                                                class="styleinput"
                                                v-model="password_confirmation"
                                                required
                                            />
                                            <div
                                                class="status"
                                                id="password_confirmation-status"
                                            ></div>
                                            <div v-if="ress && ress.password" class="text-danger">
                                                {{ ress.password[0] }}
                                            </div>
                                            <br /> -->

                                            <label class="input-icon"
                                                ><i class="fa fa-mobile"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="mobile"
                                                placeholder="Mobile Number"
                                                class="styleinput"
                                                v-model="mobile"
                                                required
                                            />
                                            <div class="status" id="mobile-status"></div>
                                            <!-- <div v-if="ress && ress.mobile" class="text-danger">
                                                {{ ress.mobile[0] }}
                                            </div> -->
                                            <br />
                                            <select
                                                class="reg-sel-1"
                                                name="gender"
                                                style="width: 100%"
                                                v-model="gender"
                                                required
                                            >
                                                <option disabled value="">Gender</option>
                                                <option value="male">male</option>
                                                <option value="female">female</option>
                                            </select>
                                            <div class="status" id="gender-status"></div>
                                            <br /><br />
                                            <div class="my-1 mt-2">Date of birth</div>
                                            <input
                                                type="date"
                                                name="date"
                                                class="date-input"
                                                v-model="date"
                                                style="width: 100% !important"
                                                required
                                            />
                                            <div class="status" id="date-status"></div>
                                            <br />

                                            <label class="input-icon"
                                                ><i class="fa fa-map-marker"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="address"
                                                placeholder="Address"
                                                class="styleinput"
                                                v-model="address"
                                                required
                                            />
                                            <div class="status" id="address-status"></div>
                                            <br />

                                            <select
                                                class="reg-sel-1"
                                                name="state"
                                                v-model="state"
                                                v-on:change="getcity"
                                                required
                                            >
                                                <option disabled value="">State</option>
                                                <option
                                                    v-for="user in category"
                                                    :key="user.state_code"
                                                    :value="user.state_code"
                                                    >{{ user.state_name }}</option
                                                >
                                            </select>
                                            <div class="status" id="state-status"></div>
                                            <select
                                                name="city"
                                                v-model="city"
                                                class="reg-sel-1"
                                                required
                                            >
                                                <option disabled value="">City</option>
                                                <option
                                                    v-for="(user, index) in cities"
                                                    :key="index"
                                                    :value="user.city_code"
                                                    >{{ user.city_name }}</option
                                                >
                                            </select>
                                            <div class="status" id="city-status"></div>
                                            <br />
                                            <br />

                                            <label class="input-icon"
                                                ><i class="fa fa-map-marker"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="pincode"
                                                placeholder="Pincode"
                                                class="styleinput"
                                                v-model="pincode"
                                                required
                                            />
                                            <div class="status" id="pincode-status"></div>
                                            <br />

                                            <label>
                                                <input
                                                    type="radio"
                                                    name="agree"
                                                    value="1"
                                                    v-model="agree"
                                                    required
                                                />
                                                <p>
                                                    By creating account you agree<br />
                                                    to our <a href="#">Terms & Conditions</a>
                                                </p>
                                            </label>
                                            <div class="status" id="agree-status"></div>
                                            <button
                                                type="submit"
                                                class="registerbtn"
                                                @click="register"
                                            >
                                                CREATE NOW
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-sm-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { HTTP } from "../_helper/http-constants"
import request from "../apis/request"
import scrollTop from "./utils/scrollTop"
import { mapState, mapActions } from "vuex"

export default {
    name: "register",

    data() {
        return {
            ress: {},
            cities: null,
            category: null,
            name: "",
            email: "",
            password: "",
            password_confirmation: "",
            profileCompletionMode: false,
            showPassword: false,
            mobile: "",
            gender: "",
            date: "",
            address: "",
            state: "",
            city: "",
            pincode: "",
            agree: "",
            submitAttempted: false,

            action: "/materialregister",
        }
    },

    watch: {
        name(e) {
            this.updateValidation()
        },
        email(e) {
            this.updateValidation()
        },
        password(e) {
            this.updateValidation()
        },
        mobile(e) {
            this.updateValidation()
        },
        gender(e) {
            this.updateValidation()
        },
        date(e) {
            this.updateValidation()
        },
        address(e) {
            this.updateValidation()
        },
        state(e) {
            this.updateValidation()
        },
        city(e) {
            this.updateValidation()
        },
        pincode(e) {
            this.updateValidation()
        },
        agree(e) {
            this.updateValidation()
        },
    },
    mounted() {
        scrollTop()
        const completedProfile = this.user && this.authenticated && !!this.user.profile_completed

        console.log(this.user, this.authenticated, completedProfile)

        if (this.user && !completedProfile) {
            // show the email verification component
            this.profileCompletionMode = true
        }
    },
    methods: {
        ...mapActions("auth", {
            login: "login",
        }),
        updateValidation() {
            if (!this.submitAttempted) return
            console.log("update validation running")

            if (this.name.length == 0) {
                document.getElementById("name-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("name-status").innerHTML = ""
            }

            if (this.password.length < 6) {
                document.getElementById("password-status").innerHTML =
                    "<span class='warning' style='color: red;'>Password must be minimum 6 characters.</span>"
            } else {
                document.getElementById("password-status").innerHTML = ""
            }

            if (this.gender.length == 0) {
                document.getElementById("gender-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("gender-status").innerHTML = ""
            }
            if (this.date.length == 0) {
                document.getElementById("date-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("date-status").innerHTML = ""
            }
            if (this.address.length == 0) {
                document.getElementById("address-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("address-status").innerHTML = ""
            }
            if (this.state.length == 0) {
                document.getElementById("state-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("state-status").innerHTML = ""
            }
            if (this.city.length == 0) {
                document.getElementById("city-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("city-status").innerHTML = ""
            }
            if (this.pincode.length == 0) {
                document.getElementById("pincode-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("pincode-status").innerHTML = ""
            }
            if (this.agree.length == 0) {
                document.getElementById("agree-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("agree-status").innerHTML = ""
            }

            for (let error in this.ress) {
                const id = error + "-status"
                const msg = this.ress[error][0]
                const el = document.getElementById(id)
                el.innerHTML = `<span class='warning' style='color: red;'>${msg}</span>`
                console.log(id, "error", msg, el)
            }

            if (Object.values(this.ress).length > 0) {
                return
            }

            if (this.email.length == 0) {
                document.getElementById("email-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("email-status").innerHTML = ""
            }

            // if (this.password_confirmation.length == 0) {
            //     document.getElementById("password_confirmation-status").innerHTML =
            //         "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            // } else {
            //     document.getElementById("password_confirmation-status").innerHTML = ""
            // }
            if (this.mobile.length == 0) {
                document.getElementById("mobile-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                console.log("removing mobile message", this.ress)
                document.getElementById("mobile-status").innerHTML = ""
            }
        },
        register: function(e) {
            e.preventDefault()
            this.submitAttempted = true
            this.updateValidation()

            if (
                !(
                    this.name.length > 0 &&
                    this.password.length >= 6 &&
                    // this.password_confirmation.length > 0 &&
                    this.mobile.length > 0 &&
                    this.gender.length > 0 &&
                    this.date.length > 0 &&
                    this.address.length > 0 &&
                    this.state.length > 0 &&
                    this.city.length > 0 &&
                    this.pincode.length > 0 &&
                    this.validateEmail() &&
                    this.validatemobile()
                )
            ) {
                this.$toasted.error("Please correct the errors before submitting the form")
                return
            }
            request
                .post(this.action, {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    // password_confirmation: this.password_confirmation,
                    mobile: this.mobile,
                    gender: this.gender,
                    date: this.date,
                    address: this.address,
                    state: this.state,
                    city: this.city,
                    pincode: this.pincode,
                })
                .then((res) => {
                    this.ress = {}

                    this.$toasted.success("Material Register successful", {
                        keepOnHover: true,
                        iconPack: "fontawesome",
                        icon: "check",
                        theme: "toasted-primary",
                        // timerProgressBar: true,
                        duration: 3000,
                    })

                    this.login({ email: this.email, password: this.password })
                        .then(async (res) => {
                            window.location.reload()
                            // await this.fetchUser()
                            // this.$router.push({ name: "dashboard" })
                        })
                        .catch(() => {
                            console.log("error in logging in user")
                        })

                    //   this.$router.push({ name: "login" });
                })
                .catch((res) => {
                    //alert(err);
                    if (res.response.status === 422) {
                        this.ress = res.response.data.errors || {}
                    }
                    this.$toasted.error("Please correct the errors before submitting the form")
                    this.updateValidation()
                    return res
                })
        },

        validateEmail: function() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            let msg
            if (res === false)
                msg = "<span class='warning' style='color: red;'>Email is not valid yet.</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("email-status").innerHTML = msg
            return res
        },

        validatemobile: function() {
            let res = true
            let digitCount = 0
            for (let i = 0; i < this.mobile.length; i++) {
                if (!(this.mobile[i] >= "0" && this.mobile[i] <= "9")) {
                    res = false
                } else {
                    digitCount++
                }
            }
            let msg
            if (res == false)
                msg = "<span class='warning' style='color: red;'>Oops!!! Digits only.</span>"
            else if (digitCount != 10)
                msg = "<span class='warning' style='color: red;'>No!!! Just 10 digits</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("mobile-status").innerHTML = msg
            return res && digitCount == 10
        },

        getcity() {
            axios.get("/api/city/" + this.state).then((response) => (this.cities = response.data))
        },
    },

    created() {
        axios
            .get("/api/state")

            .then((response) => (this.category = response.data))
    },
}
</script>
